import { reactive } from "vue";
/*
// 引入全部图标
 import * as Icons from "@element-plus/icons"
 const ggIcon = Icons
*/

// 按需引入图标
import {
  User,
  School,
  DocumentAdd,
  Rank,
  Files,
  Memo,
  Pointer,
  Shop,
  ElemeFilled,
  ChromeFilled,
  Reading,
  Plus,
  SetUp,
  ScaleToOriginal,
  Avatar,
  Cpu,
  DataLine,
  Management,
  House,
  Fold,
  Expand,
  Setting,
  Help,
  Edit,
  CloseBold,
  ElementPlus,
  Operation,
  Notification,
  CircleCloseFilled,
  Magnet,
  Connection
} from "@element-plus/icons-vue";

const elIcon = {
  User,
  School,
  DocumentAdd,
  Rank,
  Files,
  Memo,
  Pointer,
  Shop,
  ElemeFilled,
  ChromeFilled,
  Reading,
  Plus,
  SetUp,
  ScaleToOriginal,
  Avatar,
  Cpu,
  DataLine,
  Management,
  House,
  Fold,
  Expand,
  Setting,
  Help,
  Edit,
  CloseBold,
  ElementPlus,
  Operation,
  Notification,
  CircleCloseFilled,
  Magnet,
  Connection
};
export default elIcon;
