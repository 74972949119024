import { get, post } from '@/utils/service'

// 上传简版征信
const uploadCredit = (file) => post('/tools/creditAuto', file, {
    'Content-type': 'multipart/form-data'
})

// 经营数据获取-------------------------------------------------------------------
const innitHttp = (data) => post('/tax/innitHttp', data)

// 获取验证码
const getCaptcha = () => get('/tax/getCaptcha')

// 发送验证码坐标
const submitCaptcha = (data) => post('/tax/submitCaptcha', data)

// 发起数据查询
const first_request = (data) => post('/tax/first_request', data)

// 发送短信验证码
const submitPersonCaptch = (data) => post('/tax/submitPersonCaptch', data)

// 主登录函数
const submitMain = (data) => post('/tax/submitMain', data)

// 获取本月历史记录
const getHistoryName = () => get('/tax/getHistoryName')

// 获取单个历史数据
const getHistoryData = (data) => post('/tax/getHistoryData', data)

// 测试开票信息用的路由
const getManageData = () => get('/tax/getManageData')

// 将数据保存至案件
const saveManageDataToCase = (data) => post('/tax/saveManageDataToCase', data)

// 计算企业的客户占比
const fromTaxDataGetCompanyRate = (data) => post('/tax/fromTaxDataGetCompanyRate', data)


// -新版登陆------------------------------------
const newLoginInit = (data) => post('/tax/newLoginInit', data)
// 发送验证码
const newLoginSendSms = (data) => post('/tax/newLoginSendSms', data)
// 主登陆
const newLoginMainLogin = (data) => post('/tax/newLoginMainLogin', data)


// 发送文件至企业征信识别模块
const readCompanyCredit = (file) => post('/tools/readCompanyCredit', file, {
    'Content-type': 'multipart/form-data'
})

// 通过输入的公司名称,匹配相关的企业名称及税号
const getCompanyNameFromSever = (data) => post('/tools/getCompanyNameFromSever', data)

// 获取纳税人识别号
const getNsrsbh = (data) => post('/tools/getNsrsbh', data)

export default {
    uploadCredit,
    getCaptcha,
    submitCaptcha,
    first_request,
    submitPersonCaptch,
    submitMain,
    getHistoryName,
    getHistoryData,
    getManageData,
    saveManageDataToCase,
    innitHttp,
    fromTaxDataGetCompanyRate,
    readCompanyCredit,
    newLoginInit,
    newLoginSendSms,
    newLoginMainLogin,
    getCompanyNameFromSever,
    getNsrsbh
}