const caseIndexOptions = {
    namespaced: true,
    actions: {
        updateCases(context, value) {
            context.commit("UPDATECASES", value);
        },
    },
    mutations: {
        UPDATECASES(state, value) {
            state.cases = value;
        },
    },
    state: {
        cases: [],
    },
    getters: {},
};

export default caseIndexOptions;
