import {get,post} from '@/utils/service'

// 获取所有的字段
const getUserWord = ()=>get('word/getUserWord')

// 获取管理使用的字段
const getManageWords = ()=>get('word/getManageWords')

// 保存客户标签字段
const saveCustomMark = (data)=>post('word/saveCustomMark',data)

// 保存管理用字段
const saveManageWords = (data)=>post('word/saveManageWords',data)

export default{
    getUserWord,
    saveCustomMark,
    getManageWords,
    saveManageWords
}