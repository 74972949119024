import { defineComponent } from 'vue';
import { ElConfigProvider } from 'element-plus';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'App',
  components: {
    ElConfigProvider
  },
  setup() {
    const router = useRouter();
    // router.push({
    //   // path:'/'
    //   path:'/login'
    // })

    return {
      zIndex: 3000,
      size: 'small'
    };
  }
});