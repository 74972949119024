import { createApp } from 'vue'
// import '@/style/element/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './App.vue'

// 导入Vue-router
import router from '@/router/index'
import '@/router/permission'

// 导入vuex
import store from '@/store/index'

// 导入axios
// import api from '@/api/index'

// 导入图标库
import fontawesomeUI from './utils/fontawesomeUI'

// 导入el图标
import elIcon from './utils/elementIcon'

// 导入加密库
import { RsaEncrypt } from '@/utils/Encrypt.js'

// 导入自定义组件
// import components from "@/customComponents";
import components from "@/customComponents/index";

// element-plus相关支持
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn'


// 导入echart
import ECharts from './utils/ECharts'

const app = createApp(App)

//icon 部分注册
app.config.globalProperties.icon = elIcon;

app.use(router)
app.use(store)
// app.use(api)
app.use(RsaEncrypt)
app.component('font-awesome-icon', fontawesomeUI)
app.component('ECharts', ECharts)
app.mount('#app')
app.use(components);
app.use(ElementPlus, {
  locale: zhCn,
})

// icon 全局注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


// icon有注册两次,注意修改


// 全局注册自定义指令,控制按钮权限
app.directive('permission', {
  mounted(el, binding) {
    if (!store.state.permission[binding.value]) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
})