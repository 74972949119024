import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'NowOverdue',
  props: ['index', 'item'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const word = {
      name: 'NowOverdue'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index)
      }, {
        default: _withCtx(() => [_createTextVNode(" 当前逾期结清可做 ")]),
        _: 1
      }, 8, ["index"]);
    };
  }
};