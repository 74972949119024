import { createStore } from "vuex";
import { newTimestamp } from '@/utils/timeChange'

import tabOptions from "@/store/indexContent/tabZone";
import indexMenusOption from "@/store/indexMenu/indexMenus";
import newCusOptions from "@/store/customer/newCus";
import customerIndexOptions from "@/store/customer/customerIndex";
import customerDetailsOptions from "@/store/customer/customerDetails";
import caseIndexOptions from "@/store/case/caseIndex";
import caseDetailsOptions from "@/store/case/caseDetails";
import productIndexOptions from "@/store/product/productIndex";
import productDetailsOptions from "@/store/product/productDetails";
import channelIndexOptions from "@/store/channel/channelIndex";
import channelDetailsOptions from "@/store/channel/channelDetails";
import wordOption from "./word/word";
import nowActiveTab from '@/store/other/nowActiveTab'
import tempDataOption from '@/store/other/tempData'
import permission from '@/store/user/permission'
import manageDataOption from '@/store/manageTool/manage'
import msgOption from '@/store/msg/index'

export default createStore({
  // 保存至localStorage
  state: {
    // access_token
    get UserToken() {
      return localStorage.getItem("access_token");
    },
    set UserToken(value) {
      localStorage.setItem("access_token", value);
    },
    // 刷新token
    get refreshToken() {
      return localStorage.getItem('refresh_token')
    },
    set refreshToken(value) {
      localStorage.setItem('refresh_token', value)
    },
    // 时间有效期
    get expirationTime() {
      return localStorage.getItem('expirationTime')
    },
    set expirationTime(value) {
      localStorage.setItem('expirationTime', value)
    },
    get userInfo() {
      return JSON.parse(localStorage.getItem("user_info"));
    },
    set userInfo(value) {
      localStorage.setItem("user_info", JSON.stringify(value));
    }
  },
  mutations: {
    // token过期时间设置
    TOKENCOUNT(state) {
      const timestamp = newTimestamp(2)
      state.expirationTime = timestamp
    },
    // 登录保存token至vuex
    LOGIN_IN(state, data) {
      state.UserToken = data.token.access_token
      state.refreshToken = data.token.refresh_token
      state.userInfo = data.userInfo

      // 设置有效期默认值
      const timestamp = newTimestamp(2)
      state.expirationTime = timestamp
    },
    // 登出
    LOGIN_OUT(state) {
      state.UserToken = "";
      state.refreshToken = '';
      state.expirationTime = '';
      state.userInfo = '';
      wordOption.state.manageWords = "";
      wordOption.state.words = "";
    },
  },

  modules: {
    tabOptions,
    indexMenusOption,
    newCusOptions,
    customerIndexOptions,
    customerDetailsOptions,
    caseIndexOptions,
    caseDetailsOptions,
    productIndexOptions,
    productDetailsOptions,
    channelIndexOptions,
    channelDetailsOptions,
    wordOption,
    nowActiveTab,
    tempDataOption,
    permission,
    manageDataOption,
    msgOption
  },
});
