import axios from "axios";
import store from '@/store/index'
import { ElMessage } from 'element-plus';

let baseURL;
if (process.env.NODE_ENV == 'development') {
    baseURL = 'http://localhost:8080/flask'
} else {
    // baseURL = process.env.baseURL
    baseURL = 'loan'
}

const $http = axios.create({
    baseURL,
    timeout: 1000000
})

window.isRefreshing = false;
// 存储请求的数组
let cacheRequestArr = [];

// 将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]
function cacheRequestArrHandle(cb) {
    cacheRequestArr.push(cb);
}
// 数组中的请求得到新的token之后自执行，用新的token去重新发起请求
function afreshRequest(token) {
    cacheRequestArr.map(cb => cb(token));
    cacheRequestArr = [];
}
// 判断token是否即将过期
function isTokenExpired() {
    // 当前时间
    let curTime = new Date().getTime();

    // 到期时间减当前时间
    let expiresTime = Number(store.state.expirationTime) - curTime;

    // 还差10分钟即将过期或者已经过期了，但过期时间在20分钟内
    if ((expiresTime >= 0 && expiresTime < 1150000) || (expiresTime < 0 && Math.abs(expiresTime) <= 1200000)) {
        return true
    }
    return false;
}

// // 添加请求拦截器
$http.interceptors.request.use(
    config => {
        if (store.state.UserToken || store.state.UserToken != undefined) {
            // 正常请求
            config.headers.Authorization = 'Bearer ' + store.state.UserToken

            if (isTokenExpired() && config.url !== '/refresh') {
                // 所有的请求来了，先判断是否正在刷新token，
                // 如果不是，将刷新token标志置为true并请求刷新token.
                // 如果是，则先将请求缓存到数组中
                // 等到刷新完token后再次重新请求之前缓存的请求接口即可
                if (!window.isRefreshing) {
                    // 标志改为true，表示正在刷新
                    window.isRefreshing = true;
                    refresh()
                    // 下面这段代码一定要写，不然第一个请求的接口带过去的token还是原来的，要将第一个请求也缓存起来
                    let retry = new Promise((resolve) => {
                        cacheRequestArrHandle((token) => {
                            config.headers['Authorization'] = token; // token为刷新完成后传入的token
                            // 将请求挂起
                            resolve(config)
                        })
                    })
                    return retry;
                } else {
                    let retry = new Promise((resolve) => {
                        cacheRequestArrHandle((token) => {
                            config.headers['Authorization'] = token; // token为刷新完成后传入的token
                            // 将请求挂起
                            resolve(config)
                        })
                    })
                    return retry;
                }
            } else {
                return config
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

//添加响应拦截器
$http.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求出错'
                    break
                case 401:
                    // ElMessage.warning({
                    //     message: '登录失效,请重新登录'
                    // })
                    store.commit('LOGIN_OUT')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    return
                case 403:
                    error.message = '拒绝访问'
                    break
                case 404:
                    error.message = '请求错误,未找到该资源'
                    break
                case 422:
                    error.message = '请求头错误'
                    break
                case 429:
                    error.message = '请求过于频繁'
                    break
                case 500:
                    error.message = '服务端出错'
                    break
            }
        } else {
            error.message = '连接服务器失败'
        }
        ElMessage.error({
            message: error.message
        })
        return Promise.reject(error.response)
    }
)

export const get = (url, params) => {
    params = params || {};
    return new Promise((resolve, reject) => {
        $http
            .get(url, params)
            .then((res) => {
                resolve(res)
            })
            .catch(e => {
                console.log(e);
            })
    })

}

export const post = (url, params) => {
    params = params || {};
    return new Promise((resolve, reject) => {
        $http
            .post(url, params)
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                console.log(error);
            })
    })
}

// 刷新token
const refresh = async () => {
    // 携带刷新token请求刷新
    const http = axios.create({
        baseURL,
        timeout: 3000,
        headers: {
            Authorization: 'Bearer ' + store.state.refreshToken
        }

    })
    const data = {
        'access_token': store.state.UserToken,
        'refresh_token': store.state.refreshToken
    }

    await http.post('/refresh', data).then((res) => {
        // 更新usertoken
        store.state.UserToken = res.data.access_token
        store.state.refreshToken = res.data.refresh_token
        // 更新有效期
        store.commit('TOKENCOUNT')
        // 更新后发送请求
        afreshRequest('Bearer ' + res.data.access_token)
    }).catch((err) => {
        // 请求刷新token失败（刷新token过期）
        ElMessage.warning({
            message: '登录失效,请重新登录'
        })
        store.commit('LOGIN_OUT')
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }).finally(() => {
        window.isRefreshing = false;
    })
}
