import store from "@/store/index";

const tabOptions = {
    namespaced: true,
    actions: {
        selectMenu(context, value) {
            context.commit("SELECTMENU", value);
        },

        delTab(context, value) {
            context.commit("DELTAB", value);
        },
        displayTab(context, value) {
            context.commit("DISPLAYTAB", value);
        },
        // 多选客户,不能放入当前选项
        multiCusSelected(context, value) {
            context.commit("MULTICUSSELECTED", value);
        },
        // 清空tab
        clearTabList(context) {
            context.commit("CLEARTABLIST");
        },
        // 替换tab标签的名称,用于各类新建
        updateTabName(context, value) {
            // data有三个内容,一个是新名称,另一个是最新的ID,以及类别
            context.commit("UPDATETABNAME", value);
        },
    },
    mutations: {
        MULTICUSSELECTED(state, value) {
            let tabs = state.tabList;
            // 如果数组中没有数据，先添加
            if (tabs.length == 0) {
                state.tabList.push(value);
            } else {
                // 判断数组中是否有过数据
                let index = tabs.indexOf(value);
                // 如果有就添加，没有就什么也不做
                const newVaule = JSON.parse(JSON.stringify(value));
                index == -1 ? state.tabList.push(newVaule) : null;
            }
        },

        // 新增标签,选中标签
        SELECTMENU(state, value) {
            let tabs = state.tabList;
            // 如果数组中没有数据，先添加
            if (tabs.length == 0) {
                state.tabList.push(value);
            } else {
                // 判断数组中是否有过数据
                let index = tabs.indexOf(value);
                // 如果有就添加，没有就什么也不做
                index == -1 ? state.tabList.push(value) : null;
            }

            state.activeName = value.name;
        },

        //删除标签
        DELTAB(state, value) {
            let list = state.tabList;
            let listIndex;
            list.forEach((item) => {
                listIndex = list.indexOf(item);
                if (item.name == value) {
                    list.splice(listIndex, 1);
                }
            });

            const nextTab =
                state.tabList[listIndex + 1] || state.tabList[listIndex - 1];
            state.delTemp = nextTab;

            if (nextTab) {
                state.activeName = nextTab.name;
            }

            // 关闭至最后一个时,清空菜单选项
            if (nextTab.name == "系统主页" && state.tabList.length == 1) {
                store.dispatch("indexMenusOption/clearMenuActive");
            }
        },

        DISPLAYTAB(state, value) {
            state.activeName = value;
        },
        CLEARTABLIST(state) {
            state.tabList = [];
            state.activeName = "";
        },
        UPDATETABNAME(state, value) {
            const activeName = state.activeName;
            const tabList = state.tabList;
            tabList.forEach((item) => {
                if (item.name == activeName) {
                    item.name = value.name;
                    item.type = value.type;
                    item.path = value.path;
                    item.contentId = value.contentId;
                    if (item.contentId) {
                        item.contentId = value.contentId;
                    }
                    // 当前名称更新
                    state.activeName = value.name;
                }
            });
        },
    },
    state: {
        // tabList 是标签页的所有标签的数组
        tabList: [],
        activeName: "",
        delTemp: "",
    },
    getters: {},
};

export default tabOptions;
