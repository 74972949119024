import { get, post } from '@/utils/service'

// 更新产品信息
const getSpideWords = (data) => post('control/getSpideWords', data)

// 获取Manage中spideManage数据
const getSpideManageData = () => get('/manage/getSpideManageData')

// 保存Manage中spideManage数据
const saveSpideManageData = (data) => post('/manage/saveSpideManageData', data)


export default {
    getSpideWords,
    getSpideManageData,
    saveSpideManageData
}