import { get, post } from "@/utils/service";
import { DEFAULT_FORMATS_DATE } from "element-plus";

// 根据案件ID获得案件详情
const getCaseDetails = (caseId) => post("/case/getCaseDetails", caseId);

// 获取该用户下所有的案件
const getAllCase = (data) => post("/case/getAllCase", data);

// 修改案件基本信息
const alterCaseBaseInfo = (baseInfo) => post("/case/alterBaseInfo", baseInfo);

// 修改案件申请人信息
const alterCaseApplicant = (applicant) =>
    post("/case/alterApplicant", applicant);

// 根据名称获取企业信息
const getCompanyInfo = (companyName) =>
    post("/case/getCompanyInfo", companyName);

// 保存企业基本信息
const saveCompanyInfo = (companyInfo) =>
    post("case/saveCompanyInfo", companyInfo);

//保存企业股份信息
const saveCompanyShare = (share) => post("case/saveCompanyShare", share);

// 保存企业变更信息
const saveCompanyChange = (change) => post("case/saveCompanyChange", change);

// 保存企业证书信息
const saveCertificate = (data) => post("case/saveCertificate", data);

// 保存企业中标信息
const saveBidding = (data) => post("case/saveBidding", data);

// 保存司法信息
const saveLawsuit = (data) => post("case/saveLawsuit", data);

// 保存征信手动模式数据
const saveCreditManual = (data) => post("case/saveCreditManual", data);

// 保存手动销售数据
const saveCompanyManualCreditData = (title, data) => {
    switch (title) {
        case "开票信息":
            return post("case/saveCompanyManualSalesData", data);
        case "纳税信息":
            return post("case/saveCompanyManualTaxData", data);
    }
};

// 删除申请人/担保人
const delapplicantOrCompany = (data) =>
    post("case/delapplicantOrCompany", data);

// 保存操作
const saveOperation = (data) => post("case/saveOperation", data);

// 删除案件
const delCase = (data) => post("case/delCase", data);

// 搜索案件
const searchCase = (data) => post("case/searchCase", data);

// 保存方案
const savePlan = (data) => post("case/savePlan", data);

// 自动匹配
const autoMatch = (data) => post("case/autoMatch", data);

// 保存记录
const saveRecord = (data) => post("case/saveRecord", data);

// 保存配置
const saveConfig = (data) => post("case/saveConfig", data);

// 自动获取企业信息
const getCompanyInfoAuto = (data) => post("case/getCompanyInfoAuto", data);

export default {
    getCaseDetails,
    getAllCase,
    alterCaseBaseInfo,
    alterCaseApplicant,
    getCompanyInfo,
    saveCompanyInfo,
    saveCompanyShare,
    saveCompanyChange,
    saveCertificate,
    saveBidding,
    saveLawsuit,
    saveCreditManual,
    saveCompanyManualCreditData,
    saveOperation,
    delCase,
    searchCase,
    savePlan,
    autoMatch,
    saveRecord,
    saveConfig,
    delapplicantOrCompany,
    getCompanyInfoAuto,
};
