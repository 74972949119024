import { JSEncrypt } from "jsencrypt";

// rsa服务器数据加密,私钥在服务器,与下方相比为不同的两对KEY
const RsaEncrypt = (password) => {
    const encrypt = new JSEncrypt();
    // 公钥
    const publicKey =
        "MIGJAoGBAISWXAC2gAYV0TeYbPzaSN2Dgz683+jEUfPvaN8eA80cp2H07lPFeMjm\njbe92KmP3r01JFpHE30rLH4eoO33ph/JoJMj3+VwzrKlTe0ZYyDoyGHIdeV3Mghl\n97CI96LZV2zwqdYg8XPcbXMUfim+Bk9OdU/vnPVNLw3GsUPTyIcxAgMBAAE=";
    encrypt.setPublicKey(publicKey); // 此处设置公钥
    const data = encrypt.encrypt(password); // 这里对密码进行加密
    return data;
};

// rea 保存密码加密和解密,私钥在这里
// 生成RSA密钥对
// const encryptor = new JSEncrypt();
// const keyPair = encryptor.getKey();
// const publicKey = keyPair.getPublicKey();
// console.log("publicKey", publicKey);
// const privateKey = keyPair.getPrivateKey();
// console.log("privateKey", privateKey);

const publicKey = `-----BEGIN PUBLIC KEY-----
            MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCV5fzCSCBv/SUEPdC/HbRjXDDN
            W/vTOEV4IWGTDKar8viu91W0Ncx47AaG/mfH/CKZrLFhB1iWLFrBtZXPNtqHUy6L
            4vqeWxrAEZdlY01KEbE6Bu88Cg7Z3pSsCBTYLSfTAfJ5R51lz/i2H6gSW/F4svkU
            DYZMY6M+huZC4L1vyQIDAQAB
            -----END PUBLIC KEY-----`;

const privateKey = `-----BEGIN RSA PRIVATE KEY-----
            MIICWwIBAAKBgQCV5fzCSCBv/SUEPdC/HbRjXDDNW/vTOEV4IWGTDKar8viu91W0
            Ncx47AaG/mfH/CKZrLFhB1iWLFrBtZXPNtqHUy6L4vqeWxrAEZdlY01KEbE6Bu88
            Cg7Z3pSsCBTYLSfTAfJ5R51lz/i2H6gSW/F4svkUDYZMY6M+huZC4L1vyQIDAQAB
            AoGAfiQR9bQYh+Uw90bFmW531H1KHCZh3jcmTpdYsxQcVeqbVzGQD5yOOtv0Cjmg
            P+xveX6Xrh0ucQFWRlh2fu+cKKcgkwOQ9aoYIyGN5CX80EwcKLYr7eAn3m+LaFEF
            PbeL7oXQurWYOhkEUupr+yDwO47JjWB+OrQhJK9xbUQbo2ECQQDGT/O3DPy5hRUY
            VLfqffSGPJKuwoDqQsywGFswORN6kBUeFkYuLcsir0TvUd+DzeyKRbC9S/FdUBbs
            Ln20drm1AkEAwYC3XUpJd7TbC+XEW2KZ7xsGO3nCGI6SVQhVk3Bqq1M+6sewd0Wf
            2foc1T/dK1yOjKnJqrDbe4rc4scozv0aRQJAdxYt+T+AR/vF1i2Rn+vxOecIAo/k
            kEh62EhYAhZUPyZkuAOSM0rmkDaGA9y7h0/Sepos7w+6qllc2ol0jTk6fQJAJ98d
            6VslSEU73Xe6vhnTHjGzVw3/sI+wF1VgTMEdObAthrMe6XSvO3sN9eLy77xioxlZ
            NJK8pvg2C4R+JCiDAQJAd9YI/RwkGwcEUq+vQp3r7FNU2kRJN1XP7rbqAZNR1vEu
            22kAEC0x6KrKpL1EhjmPKbrC1KPAQ4l3Ndy7Ob4oFQ==
            -----END RSA PRIVATE KEY-----`;

// 加密数据
const encryptSession = (msg) => {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    const encrypted = encryptor.encrypt(msg);
    return encrypted;
};

// 解密数据
const decryptSession = (msg) => {
    const decryptor = new JSEncrypt();
    decryptor.setPrivateKey(privateKey);
    const decrypted = decryptor.decrypt(msg);
    return decrypted;
};

export { RsaEncrypt, encryptSession, decryptSession };
