import { get, post } from "@/utils/service";

// 更新产品信息
const refrushProduct = (data) => post("control/refrushProduct", data);

// 版本更新按钮
const productFlush = () => get("control/productFlush");

// 修正数据库数据
const flushProduct = () => get("control/flushProduct");

export default {
    refrushProduct,
    productFlush,
    flushProduct,
};
