const productDetailsOptions = {
    namespaced: true,
    actions: {
        // 初始化详情
        initDetails(context, value) {
            context.commit("INITDETAILS", value);
        },

        addProList(context, value) {
            context.commit("ADDPROLIST", value);
        },
        // 删除各类里的临时tab位置list,标签删除时需要一起删除
        removeActiveTabTemp(context, value) {
            context.commit("REMOVEACTIVETABTEMP", value);
        },
    },
    mutations: {
        INITDETAILS(state, value) {
            state.details = value;
        },

        ADDPROLIST(state, value) {
            state.proList[value.proId] = value.content;
        },
        REMOVEACTIVETABTEMP(state, value) {
            delete state.proList[value];
        },
    },
    state: {
        // 各项审核要求的项
        // 企业
        company: [
            {
                value: "Company",
                label: "企业要求",
                children: [
                    {
                        value: "GeneralCompany",
                        label: "通用",
                    },
                    {
                        value: "AgeLimit",
                        label: "成立时间",
                    },
                    {
                        value: "LegalLatestChange",
                        label: "法人变更",
                    },
                    {
                        value: "Industry",
                        label: "行业要求",
                    },
                    {
                        value: "CompanyArea",
                        label: "注册地区要求",
                    },
                    {
                        value: "RegisteredCapital",
                        label: "注册资本要求",
                    },
                    {
                        value: "CompanyType",
                        label: "企业类型要求",
                    },
                    {
                        value: "Tag",
                        label: "企业标签要求",
                    },
                ],
            },
            {
                value: "tax",
                label: "纳税要关",
                children: [
                    {
                        value: "TaxSales",
                        label: "应税销售收入",
                    },
                    {
                        value: "TaxLever",
                        label: "纳税等级要求",
                    },
                    {
                        value: "TaxYear",
                        label: "纳税金额要求",
                    },
                    {
                        value: "TaxTime",
                        label: "纳税时长要求",
                    },
                    {
                        value: "TaxRecord",
                        label: "近期纳税时长",
                    },
                    {
                        value: "NowOwingTaxes",
                        label: "当前欠税金额",
                    },
                    {
                        value: "ZeroTax",
                        label: "零申报次数",
                    },
                    {
                        value: "LateTax",
                        label: "滞纳金次数",
                    },
                ],
            },
            {
                value: "manage",
                label: "开票相关",
                children: [
                    {
                        value: "ManageSalses",
                        label: "开票金额要求",
                    },
                    {
                        value: "ManageCountLength",
                        label: "开票张数要求",
                    },
                    {
                        value: "ManageTimeLength",
                        label: "开票月份数要求",
                    },
                    {
                        value: "NoManageDays",
                        label: "连续未开票天数",
                    },
                    {
                        value: "NoManageMonth",
                        label: "连续未开票月数",
                    },
                    {
                        value: "SalesCompanyCount",
                        label: "下游企业数量",
                    },
                    {
                        value: "TotalManage",
                        label: "累计开票月数",
                    },
                    {
                        value: "LatelyManage",
                        label: "最近开票时间",
                    },
                    {
                        value: "RedManage",
                        label: "冲红金额比率",
                    },
                    {
                        value: "CancellationManage",
                        label: "作废金额比率",
                    },
                    {
                        value: "CancellationAndRedRate",
                        label: "冲红加作废比率",
                    },
                    {
                        value: "MaxMonthRate",
                        label: "最高月销售比率",
                    },
                    {
                        value: "SalesLoanRate",
                        label: "销贷比",
                    },
                    {
                        value: "QoQ",
                        label: "环比下滑要求",
                    },
                    {
                        value: "YoY",
                        label: "同比下滑要求",
                    },
                ],
            },
        ],
        // 自然人
        person: [
            {
                value: "basic",
                label: "基础",
                children: [
                    {
                        value: "General",
                        label: "通用",
                    },
                    {
                        value: "Age",
                        label: "年龄",
                    },
                    {
                        value: "Identity",
                        label: "身份",
                    },
                    {
                        value: "CensusRegister",
                        label: "户籍禁入",
                    },
                    {
                        value: "NoCredit",
                        label: "征信白户",
                    },
                ],
            },
            {
                value: "query",
                label: "查询要求",
                children: [
                    {
                        value: "Query",
                        label: "查询要求",
                    },
                    {
                        value: "QueryMyself",
                        label: "本人查询要求",
                    },
                    {
                        value: "QueryNoLoanCount",
                        label: "查询未放款",
                    },
                    {
                        value: "QueryNotBank",
                        label: "非银行查询",
                    },
                    {
                        value: "QueryOrganizationNum",
                        label: "机构查询要求",
                    },
                    {
                        value: "NetLoanCount",
                        label: "网贷申请次数",
                    },
                ],
            },
            {
                value: "debt",
                label: "负债要求",
                children: [
                    {
                        value: "DebtTotal",
                        label: "信用负债总计",
                    },
                    {
                        value: "Card",
                        label: "信用卡使用",
                    },
                    {
                        value: "CardAverage",
                        label: "信用卡平均额度",
                    },
                ],
            },
            {
                value: "overdue",
                label: "逾期要求",
                children: [
                    {
                        value: "ContinuousOverdue",
                        label: "连续逾期次数",
                    },
                    {
                        value: "TotalOverdue",
                        label: "累积逾期次数",
                    },
                    {
                        value: "NowOverdue",
                        label: "当前逾期",
                    },
                    {
                        value: "DebtCount",
                        label: "逾期程度要求",
                    },
                ],
            },
            {
                value: "other",
                label: "其它要求",
                children: [
                    {
                        value: "PhoneInNetTime",
                        label: "手机在网时长",
                    },
                    {
                        value: "Area",
                        label: "特殊地区准入",
                    },
                ],
            },
        ],
        // 抵押担保
        mortgage: [
            {
                value: "mortgage",
                label: "抵押",
                children: [
                    {
                        value: "GeneralCompany",
                        label: "通用",
                    },
                ],
            },
        ],
        // 质押担保
        pledge: [],

        // rules的初始值
        rules: {
            selected: {
                main: "自然人",
                guaranteeMode: [],
            },
            main: {
                naturalPerson: {
                    name: "自然人",
                    editComponent: false,
                    value: [],
                },
                company: {
                    name: "企业",
                    editComponent: false,
                    value: [],
                },
            },
            guaranteeMode: {
                company: {
                    name: "企业",
                    editComponent: false,
                    value: [],
                },
                person: {
                    name: "自然人",
                    editComponent: false,
                    value: [],
                },
                mortgage: {
                    name: "抵押",
                    editComponent: false,
                    value: [],
                },
                pledge: {
                    name: "质押",
                    editComponent: false,
                    value: [],
                },
            },
        },
        productInfo: {
            baseInfo: {
                proId: {
                    id: 0,
                    label: "产品ID",
                    value: "",
                    show: true,
                },
                proName: {
                    id: 1,
                    label: "产品名称",
                    value: "",
                    show: true,
                },
                organizationName: {
                    id: 5,
                    label: "资方名称",
                    value: "",
                    show: true,
                },
                organizationShortName: {
                    id: 6,
                    label: "资方简称",
                    value: "",
                    show: true,
                },
                organizationProperty: {
                    id: 7,
                    label: "资方性质",
                    value: "",
                    show: true,
                },
                loanProperty: {
                    id: 3,
                    label: "贷款性质",
                    value: "",
                    show: true,
                },
                rateFirst: {
                    id: 2,
                    label: "利率(低)",
                    value: "",
                    show: true,
                },
                rateSecond: {
                    id: 3,
                    label: "利率(高)",
                    value: "",
                    show: true,
                },
                limit: {
                    id: 4,
                    label: "最高额度",
                    value: "",
                    show: true,
                },
                repayment: {
                    id: 5,
                    label: "还款方式",
                    value: [],
                    show: true,
                },
                timeLimit: {
                    id: 6,
                    label: "期限",
                    value: [],
                    show: true,
                },
                circulation: {
                    id: 7,
                    label: "循环贷",
                    value: "",
                    show: true,
                },
                prepayment: {
                    id: 8,
                    label: "提前还款",
                    value: "",
                    show: true,
                },
                prepaymentDetails: {
                    id: 9,
                    label: "提前还款说明",
                    value: "",
                    show: true,
                },
                customerGroup: {
                    id: 10,
                    label: "产品说明",
                    value: "",
                    show: true,
                },
                applyData: {
                    id: 11,
                    label: "准备资料",
                    value: "",
                    show: true,
                },
                advantage: {
                    id: 12,
                    label: "优势",
                    value: "",
                    show: true,
                },
                other: {
                    id: 13,
                    label: "其它",
                    value: "",
                    show: true,
                },
                topFunder: {
                    id: 14,
                    label: "总行",
                    value: "",
                    show: true,
                },
                branch: {
                    id: 15,
                    label: "分行",
                    value: "",
                    show: true,
                },
                subBranch: {
                    id: 16,
                    label: "支行",
                    value: "",
                    show: true,
                },
            },
            pathInfo: {
                loanPath: {
                    id: 0,
                    label: "放款方式",
                    value: "",
                    show: true,
                },
                loanDeclare: {
                    id: 1,
                    label: "放款说明",
                    value: "",
                    show: true,
                },
                phoneCheck: {
                    id: 2,
                    label: "是否电核",
                    value: "",
                    show: true,
                },
                phoneCheckDeclare: {
                    id: 3,
                    label: "电核说明",
                    value: "",
                    show: true,
                },
                donePeriod: {
                    id: 4,
                    label: "办理周期",
                    value: "",
                    show: true,
                },
                loanControl: {
                    id: 5,
                    label: "控制放款",
                    value: "",
                    show: true,
                },
                acrossNote: {
                    id: 6,
                    label: "批款短信",
                    value: "",
                    show: true,
                },
                acrossNoteNum: {
                    id: 7,
                    label: "短信有金额",
                    value: "",
                    show: true,
                },
                autoCourse: {
                    id: 8,
                    label: "审核进度查询",
                    value: "",
                    show: true,
                },
                byeReazon: {
                    id: 9,
                    label: "拒贷原因查询",
                    value: "",
                    show: true,
                },
                pathDetails: {
                    id: 10,
                    label: "流程明细",
                    value: "",
                    show: true,
                },
            },
            backgroundInfo: {
                organizationName: {
                    id: 5,
                    label: "资方名称",
                    value: "",
                    show: true,
                },
                organizationShortName: {
                    id: 6,
                    label: "资方简称",
                    value: "",
                    show: true,
                },
                organizationProperty: {
                    id: 7,
                    label: "资方性质",
                    value: "",
                    show: true,
                },
                channelName: {
                    id: 8,
                    label: "通道名称",
                    value: "",
                    show: true,
                },
                channelProperty: {
                    id: 9,
                    label: "通道性质",
                    value: "",
                    show: true,
                },
                creditQueryName: {
                    id: 10,
                    label: "征信查询名称",
                    value: "",
                    show: true,
                },
                creditQueryReazon: {
                    id: 11,
                    label: "征集查询事由",
                    value: "",
                    show: true,
                },
                loanProperty: {
                    id: 3,
                    label: "贷款性质",
                    value: "",
                    show: true,
                },
                toMarketDate: {
                    id: 12,
                    label: "上市时间",
                    value: "",
                    show: true,
                },
                myOwnDate: {
                    id: 13,
                    label: "上架时间",
                    value: "",
                    show: true,
                },
                state: {
                    id: 4,
                    label: "状态",
                    value: "",
                    show: true,
                },
                productType: {
                    id: 1,
                    label: "产品类别",
                    value: "",
                    show: true,
                },
                productSmallType: {
                    id: 2,
                    label: "产品小类",
                    value: "",
                    show: true,
                },
                markOne: {
                    id: 14,
                    label: "自定义标签一",
                    value: "",
                    show: true,
                },
                markTwo: {
                    id: 15,
                    label: "自定义标签二",
                    value: "",
                    show: true,
                },
                markThree: {
                    id: 16,
                    label: "自定义标签三",
                    value: "",
                    show: true,
                },
                queryAuth: {
                    id: 17,
                    label: "产品权限",
                    value: false,
                    show: true,
                },
                isFirstLever: {
                    id: 18,
                    label: "总行规则",
                    value: false,
                    show: true,
                },
                isSecondLever: {
                    id: 19,
                    label: "分行规则",
                    value: false,
                    show: true,
                },
                isThirdLever: {
                    id: 20,
                    label: "支行规则",
                    value: false,
                    show: true,
                },
                isGuarantee: {
                    id: 21,
                    label: "允许担保",
                    value: false,
                    show: true,
                },
                guaranteeCompany: {
                    id: 22,
                    label: "担保公司",
                    value: [],
                    show: true,
                },
            },
        },
        words: {
            creditQueryType: [
                {
                    name: "贷款审批",
                    value: "1",
                },
                {
                    name: "信用卡审批",
                    value: "2",
                },
                {
                    name: "保前审查 ",
                    value: "3",
                },
                {
                    name: "担保资格审查 ",
                    value: "4",
                },
                {
                    name: "法人资信审查 ",
                    value: "5",
                },
            ],
        },
        proList: {},
    },
    getters: {},
};

export default productDetailsOptions;
