const productIndexOptions = {
    namespaced: true,
    actions: {
        updatePros(context, value) {
            context.commit('UPDATEPROS', value)
        }
    },
    mutations: {
        UPDATEPROS(state, value) {
            state.proTitel = value.title
            state.proData = value.data
            state.total = value.total
        }
    },
    state: {
        proTitel: [],
        proData: [],
        queryData: {
            currentPage: 1,
            pageSize: 20,
        },
        total: 0,

        // 产品基本信息缓存
        proCaches: [],

        // 产品根据类别分类好的数据,一般用于cascader(级联选择器)
        proOptions: []

    },
    getters: {

    }
}

export default productIndexOptions