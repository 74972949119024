/**
 * 按钮权限功能库
 */
const permission = {
    namespaced: true,
    state: {
        add: true,
        edit: true,
        details: true,
        del: true,
        more: true
    },
    mutations: {
        setRole(state, { name, type }) {
            state[name] = type
        }
    }
}

export default permission