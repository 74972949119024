import api from "@/api";
import store from "@/store/index";

// 获取账户所需要的各类字段并保存至vuex
const getWords = async () => {
    await api()
        .wordApi.getUserWord()
        .then((res) => {
            store.state.wordOption.words = res.data;
        });
};

const getManageWords = async () => {
    await api()
        .wordApi.getManageWords()
        .then((res) => {
            store.state.wordOption.manageWords = res.data;
        });
};

// 获取字段数据
/**
 *
 * @param {string} word  字段的id  exp:CusMarkOne
 * @param {string} typeWord 类的id  exp:cusWord
 * @param {true/false}manage  如果是系统字段为true,默认是false,自定义字段为false
 * @returns
 */
const wordList = (word, typeWord, manage = false) => {
    let data;
    manage == false
        ? (data = store.state.wordOption.words)
        : (data = store.state.wordOption.manageWords);
    let words = [];
    data.forEach((item) => {
        if (item.id == typeWord) {
            item.children.forEach((i) => {
                if (i.id == word) {
                    words = i.value || i.children;
                }
            });
        }
    });
    return words;
};

export { getWords, wordList, getManageWords };
