
const channelDetailsOptions = {
    namespaced: true,
    actions: {
        // 更新渠道信息
        updateDetails(context, value) {
            context.commit('UPDATEDETAILS', value)
        },
        // 更新单个团队信息
        updateTeams(context, value) {
            context.commit('UPDATETEAMS', value)
        },
        // 更新所有的团队信息
        updateAllTeams(context, value) {
            context.commit('UPDATEALLTEAMS', value)
        },
        // 将人员信息保存到列表,不与常规数据一致
        updateOneTeam(context, value) {
            context.commit('UPDATEONETEAM', value)
        },
        // 人员新增后更新表格
        updateOnePerson(context, value) {
            context.commit('UPDATEONEPERSON', value)
        },
        // 更新所有人员
        updataPersons(context, value) {
            context.commit('UPDATAPERSONS', value)
        },
        // 更新team当前选择项
        updateTeamCurrentRow(context, value) {
            context.commit('UPDATETEAMCURRENTROW', value)
        },
        // 更新当前面板
        updateActiveName(context, value) {
            context.commit('UPDATEACTIVENAME', value)
        }
    },
    mutations: {
        UPDATEDETAILS(state, value) {
            state.details = value
        },
        UPDATETEAMS(state, value) {
            state.details.channelTeams.unshift(value)
        },
        UPDATEALLTEAMS(state, value) {
            state.details.channelTeams = value
        },
        UPDATEONETEAM(state, value) {
            state.oneTeam = value
        },
        UPDATEONEPERSON(state, value) {
            state.oneTeam.persons.unshift(value)
        },
        UPDATAPERSONS(state, value) {
            state.oneTeam.persons = value
        },
        UPDATETEAMCURRENTROW(state, value) {
            state.teamCurrentRow = value
        },
        UPDATEACTIVENAME(state, value) {
            state.activeName = value
        }
    },
    state: {
        details: {
            channelInfo: {
                channelId: '',
                channelName: '',
                address: '',
                channelType: '',
                contact: '',
                contactPhone: '',
                principal: '',
                principalPhone: '',
                remark: '',
                shortName: ''
            },
            channelTeams: [],
        },
        oneTeam: {
            teamId: '',
            teamName: '',
            principal: '',
            principalPhone: '',
            contact: '',
            contactPhone: '',
            remark: '',
            persons: []
        },
        teamCurrentRow: '',
        // activeName: 'first'
    },

    getters: {

    }
}

export default channelDetailsOptions