import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'SalesLoanRate',
  props: ['index', 'data', 'item'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const data = computed(() => props.data);
    const item = computed(() => props.item);
    const word = {
      first: '销贷比不超过',
      second: '%',
      name: 'SalesLoanRate'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, null, 8, ["index", "data"]);
    };
  }
};