import { get, post } from "@/utils/service";

// 获取所有产品信息
const getAllPro = (data) => post("product/getAllPro", data);

// 单项搜索产品
const getAllProAloneItem = (data) => post("product/getAllProAloneItem", data);

// 获取所有的用于方案的产品
const getProNameFromPlan = () => get("product/getProNameFromPlan");

// 根据产品Id获取产品基本信息
const getProBasicInfo = (data) => post("product/getProBasicInfo", data);

// 获取产品详情
const getProDetails = (proId) => post("product/getDetails", proId);

// 保存产品信息详情
const saveProductInfo = (data) => post("product/saveProductInfo", data);

// 保存产品审核规则
const saveProductRules = (data) => post("product/saveProductRules", data);

// 获取所有的产品名称
const getAllproName = (data) => post("product/getAllproName", data);

// 根据名称搜索
const searchName = (data) => post("product/searchName", data);

// 删除产品
const delProduct = (data) => post("product/delProduct", data);

// 添加产品变更记录
const saveProChange = (data) => post("product/saveProChange", data);

// 市场产品
const getMarketPro = (data) => post("product/getMarketPro", data);

// 获取市场产品所有企业的名称
const getMarkeyAllCompanyName = (data) =>
    post("product/getMarkeyAllCompanyName", data);

// 获取市场企业的所有产品名称
const getCompanyPros = (data) => post("product/getCompanyPros", data);

export default {
    getAllPro,
    getProDetails,
    saveProductInfo,
    getAllproName,
    searchName,
    delProduct,
    saveProChange,
    getMarketPro,
    getMarkeyAllCompanyName,
    getCompanyPros,
    getProBasicInfo,
    getProNameFromPlan,
    getAllProAloneItem,
    saveProductRules,
};
