import ECharts from 'vue-echarts'
import { use } from "echarts/core";

// 手动引入 ECharts 各模块来减小打包体积
import {
    CanvasRenderer,
} from 'echarts/renderers'

import {
    BarChart,
    LineChart
} from 'echarts/charts'

import {
    GridComponent,
    TooltipComponent,
} from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    LineChart
]);

export default ECharts