import city from "@/store/other/city";

// 根据ID列表获取名称列表
const getLabelName = (idList) => {
  const nameList = [];

  idList.forEach((idItem) => {
    // 一个ID在CITY中查找
    city.forEach((item) => {
      // 如果在省级中有相等的,直接将省添加到列表
      if (item.id === idItem) {
        nameList.push(item);
      } else {
        // 如果没有,到市一级寻找
        const c = item.children.find((element) => {
          return element.id === idItem;
        });
        // 如果在市里有找到,c是找到的item
        if (c) {
          // 判断namelist中是否已经有该省
          const o = nameList.find((e) => {
            return e.id === item.id;
          });
          // 已有该省
          if (o) {
            // 判断市在不在省里面
            const otrue = o.children.find((oitem) => {
              return oitem.id === c.id;
            });
            // 如果已经有该市,null
            if (otrue) {
              null;
              // 没有的话,添加到chidren
            } else {
              o.children.push(c);
            }

            // 没有该省,添加到nameList
          } else {
            nameList.push({
              id: item.id,
              label: item.label,
              children: [c],
            });
          }
        }
      }
    });
  });
  return nameList;
};

export { getLabelName };
