const newCusOptions = {
    namespaced :true,
    actions:{
        //房产增,删
        // addHouse(context,value){
        //     context.commit('ADDHOUSE',value)
        // },
        // delHouse(context,value){
        //     context.commit('DELHOUSE',value)
        // },
        // 企业增,删
        // addCompany(context,value){
        //     context.commit('ADDCOMPANY',value)
        // },
        // delCompany(context,value){
        //     context.commit('DELCOMPANY',value)
        // },
        // // 汽车增,删
        // addCar(context,value){
        //     context.commit('ADDCAR',value)
        // },
        // delCar(context,value){
        //     context.commit('DELCAR',value)
        // },
        // // 保单增,删
        // addInsurance(context,value){
        //     context.commit('ADDINSURANCE',value)
        // },
        // delInsurance(context,value){
        //     context.commit('DELINSURANCE',value)
        // },
        // // 有价证券增,删
        // addSecurities(context,value){
        //     context.commit('ADDSECURITIES',value)
        // },
        // delSecurities(context,value){
        //     context.commit('DELSECURITIES',value)
        // },
    },
    mutations:{
        // // 房产增,删
        // ADDHOUSE(state,value){
        //     state.houses.push(value)
        // },
        // DELHOUSE(state,value){
        //     const a = state.houses.indexOf(value)
        //     state.houses.splice(a,1)
        // },
        // 企业增,删
        // ADDCOMPANY(state,value){
        //     state.companys.push(value)
            
        // },
        // DELCOMPANY(state,value){
        //     const a = state.companys.indexOf(value)
        //     state.companys.splice(a,1)
        // },
        // 汽车增,删
        // ADDCAR(state,value){
        //     state.cars.push(value)
        // },
        // DELCAR(state,value){
        //     const a = state.cars.indexOf(value)
        //     state.cars.splice(a,1)
        // },
        // 保单增,删
        // ADDINSURANCE(state,value){
        //     state.insurances.push(value)
        // },
        // DELINSURANCE(state,value){
        //     const a = state.insurances.indexOf(value)
        //     state.insurances.splice(a,1)
        // },

        // 有价证券增,删addSecurities
        // ADDSECURITIES(state,value){
        //     state.securities.push(value)
        // },
        // DELSECURITIES(state,value){
        //     const a = state.securities.indexOf(value)
        //     state.securities.splice(a,1)
        // },

    },
    state:{
        // 新建标签的数据
        // houses : [
        //     {
        //         id:1,
        //         city:'',
        //         address:'',
        //         price:''
        //     }
        // ],
        // companys:[
        //     {
        //         id:1,
        //         companyName:''
        //     }
        // ],
        // cars:[
        //     {
        //         id:1,
        //         type:'',
        //         buyTime:'',
        //         price:''
        //     }
        // ],
        // workInfo:{
        //     companyName:'',
        //     duty:'',
        //     howYear:'',
        //     selary:''
        // },
        // providentFund:{
        //     money:'',
        //     haoYear:''
        // },
        // insurances:[
        //     {
        //         id:1,
        //         buyCompany:'',
        //         priceByYear:'',
        //         haoYear:''
        //     }
        // ],
        // securities:[
        //     {
        //         id:1,
        //         name:'',
        //         price:'',
        //         other:''
        //     }
        // ],
        baseInfo:{
            cusId:'',
            name:'',
            phone:'',
            source:'',
            maintenanceMode:''
        }
        
    },
    getters:{

    }
}

export default newCusOptions