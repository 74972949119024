import { get, post } from '@/utils/service'

//注册
function register(data) {
    return post('/user/register', data)
}

// 登录
function submit(data) {
    return post('/login', data)
}

// 请求用户权限
const getPermission = () => get('user/getPermission')

// 模糊搜索用户
const getUserSearch = (data) => post('/user/getUserSearch', data)

// 检查密码是否正确
const checkPsw = (psw) => post('/user/checkPsw', psw)

// 更新密码
const updatePsw = (psw) => post('/user/updatePsw', psw)

// 修改用户资料
const updateUserdata = (data) => post('/user/updateUserdata', data)

// 获取登录日志
const getLoginLogs = (data) => post('/user/getLoginLogs', data)

// 保存历史记录
const saveCompanyHistory = (data) => post('/user/saveCompanyHistory', data)

// 获取历史记录
const getCompanyHistory = () => get('/user/getCompanyHistory')

// 获取用户角色权限
const getRolePermission = () => get('/user/getRolePermission')

// 修改角色权限
const alterRolePermission = (data) => post('/user/alterRolePermission', data)

// 删除角色
const delRole = (data) => post('/user/delRole', data)

// 获取,修改当前用户的角色列表
const userRole = (data) => post('/user/userRole', data)

export default {
    submit,
    register,
    getPermission,
    getUserSearch,
    checkPsw,
    updatePsw,
    updateUserdata,
    getLoginLogs,
    saveCompanyHistory,
    getCompanyHistory,
    getRolePermission,
    alterRolePermission,
    delRole,
    userRole
}