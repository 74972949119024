import api from "@/api";

export default {
    namespaced: true,
    actions: {
        // 显示已读或未读
        changeShowNew(context, value) {
            context.commit("CHANGESHOWNEW", value);
        },
        // 获取消息列表
        async getMsg(context, value) {
            await api()
                .msgApi.getMsg(value)
                .then((res) => {
                    context.commit("GETMSG", res);
                });
        },
    },
    mutations: {
        CHANGESHOWNEW(state, key) {
            key == true ? (state.showNew = true) : (state.showNew = false);
        },
        GETMSG(state, value) {
            (state.newMsg = value.data.newMsg),
                (state.msgReaded = value.data.msgReaded);
        },
    },
    state: {
        newMsg: [],
        msgReaded: [],
        showNew: false,
    },
    getters: {},
};
