import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

// 初始化路由
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/View/Login/index.vue')
    },
    {
        path: '/taxLogin/:id',
        name: 'TaxLogin',
        component: () => import('@/View/mobile/taxlogin/index.vue')
    }
]

/**
 * 根据用户的权限不同,所能看到的页面和可操作性不同
 */

// 准备动态加载的路由
export const DynamicRoutes = [
    {
        path: '/',
        component: () => import('@/View/layout/index.vue'),
        name: 'Container',
        meta: {
            requiresAuth: true,
            name: '首页',
            icon: 'House'
        },
        children: [
            {
                path: '/home',
                component: () => import('@/View/home/index.vue'),
                name: 'Home',
                type: 'index',
                meta: {
                    // 匹配规则
                    name: '系统主页',
                    icon: 'House',
                    keepAlive: true
                }
            }
        ]
    },
    {
        path: '/403',
        component: () => import('@/View/error/Forbidden.vue')
    },
    {
        path: '/404',
        component: () => import('@/View/error/NotFound.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes
})

export default router