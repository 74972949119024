import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from "../BaseRule.vue";
import { computed } from "vue";
export default {
  __name: 'Tag',
  props: ["index", "data"],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const data = computed(() => props.data);
    const word = {
      name: "Tag"
    };
    // 找到在列表中的本组件
    const valueData = computed(() => data.value.value[index.value]);
    return (_ctx, _cache) => {
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
          modelValue: _unref(valueData).value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(valueData).value = $event),
          class: "margin"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox, {
            label: "高新技术企业",
            value: "0"
          }), _createVNode(_component_el_checkbox, {
            label: "专精特新中小企业",
            value: "1"
          }), _createVNode(_component_el_checkbox, {
            label: "专精特新小巨人",
            value: "1"
          }), _createVNode(_component_el_checkbox, {
            label: "科技型中小企业",
            value: "2"
          }), _createVNode(_component_el_checkbox, {
            label: "国家技术创新示范企业",
            value: "3"
          }), _createVNode(_component_el_checkbox, {
            label: "国家级制造业单项冠军企业",
            value: "4"
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["index", "data"]);
    };
  }
};