import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-940953a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Zone customCom"
};
const _hoisted_2 = {
  class: "ZoneLeft formZone"
};
const _hoisted_3 = {
  key: 0,
  class: "btnEdit"
};
import { computed, watch } from 'vue';
import { wordList } from '@/hooks/system/words';
import { CloseBold } from '@element-plus/icons-vue';
export default {
  __name: 'Query',
  props: ['index', 'item', 'data'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);

    // 数据位置
    const queryData = computed(() => props.item);
    const data = computed({
      get() {
        return props.data;
      },
      set(value) {
        props.data = value;
      }
    });
    const editComponent = computed(() => data.value.editComponent);
    const reason = computed(() => queryData.value.value.isAll);
    const proWord = (word, typeWord, manage = false) => wordList(word, typeWord, manage);

    // 注销组件
    const closeSelf = () => {
      data.value.value.splice(index.value, 1);
    };

    // 如果是所有查询原因的话,全部清空
    watch(reason, () => {
      reason.value == true ? queryData.value.value.selected = [] : null;
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        "label-width": "100px",
        "label-position": "left"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form_item, {
          label: _unref(queryData).label,
          class: "itemMargin"
        }, {
          default: _withCtx(() => [_createTextVNode(" 近"), _createVNode(_component_el_input, {
            modelValue: _unref(queryData).value.one,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryData).value.one = $event),
            class: "input"
          }, null, 8, ["modelValue"]), _createTextVNode("天，查询次数少于 "), _createVNode(_component_el_input, {
            modelValue: _unref(queryData).value.two,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryData).value.two = $event),
            class: "input"
          }, null, 8, ["modelValue"]), _createTextVNode("次 ")]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_checkbox, {
          modelValue: _unref(queryData).value.isAll,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryData).value.isAll = $event),
          label: "所有查询原因",
          class: "reazon"
        }, null, 8, ["modelValue"])]), _createElementVNode("div", null, [!_unref(queryData).value.isAll ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          class: "itemMargin"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
            modelValue: _unref(queryData).value.selected,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(queryData).value.selected = $event)
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(proWord('creditQueryReazonList', 'proWord', true), item => {
              return _openBlock(), _createBlock(_component_el_checkbox, {
                label: item,
                value: item
              }, null, 8, ["label", "value"]);
            }), 256))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true)])]),
        _: 1
      }), _unref(editComponent) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_button, {
        text: "",
        onClick: closeSelf,
        icon: _unref(CloseBold)
      }, null, 8, ["icon"])])) : _createCommentVNode("", true)]);
    };
  }
};