/*
    方法一：比对路由权限
    方法二：指定返回的默认路由
*/


/**
 * 
 * @param {Array} userRouter 后台返回的路由权限 json
 * @param {Array} allRouter 前端配置好的路由权限数据
 * @returns {Array} realRouter 过滤之后的符合条件的路由
 */

export function recursionRouter(userRouter = [], allRouter = []) {
    let realRouter = [];
    allRouter.forEach((v, i) => {
        userRouter.forEach((item, index) => {
            if (item.name === v.meta.name) {
                if (item.children && item.children.length > 0) {
                    v.children = recursionRouter(item.children, v.children)
                }
                // 添加按钮权限
                for (let meta in item.meta) {
                    for (let m in v.meta) {
                        if (meta == m) {
                            v.meta[m] = item.meta[meta]
                        }
                    }
                }
                realRouter.push(v)
            }
        })
    })
    return realRouter
}

/**
 * 
 * @param {Array} routes 所有的路由
 */

export function setDefaultRoute(routes) {
    routes.forEach((v, i) => {
        if (v.children && v.children.length > 0) {
            v.redirect = { name: v.children[0].name }
            setDefaultRoute(v.children)
        }
    })
}
