import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'PhoneInNetTime',
  props: ['index'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const word = {
      first: '申请人实名制手机在网时长',
      second: '月以上',
      name: 'PhoneInNetTime'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index)
      }, null, 8, ["index"]);
    };
  }
};