// 用于临时数据

const manageDataOption = {
    namespaced: true,
    state: {
        companyName: "",
        nsrsbh: "",
        nsrInfo: {},
        nowFinancial: {
            financial: [],
            taxTable: [],
        },
        lastFinancial: {
            financial: [],
            taxTable: [],
        },
        oldFinancial: {
            financial: [],
            taxTable: [],
        },
        invoiceData: {
            monthData: [],
            buyer: [],
            manageTotal: [],
            buyer_total: [],
            kphzbhdy: {
                data: {
                    three: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    six: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    twelve: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    oldTwelve: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                },
                name: "开票汇总不含当月",
            },
            kphzhdy: {
                data: {
                    three: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    six: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    twelve: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                    oldTwelve: {
                        month: "",
                        hsxse: 0,
                        hsxse_tb: 0,
                        hsxse_hb: 0,
                        hsxse_num: 0,
                        hpje: 0,
                        hpjezb: 0,
                        zffp: 0,
                        zffpzb: 0,
                        dpys: {
                            data: [],
                            num: 0,
                        },
                    },
                },
                name: "开票汇总含当月",
            },
        },
        taxCredit: [],
        buyerData: [],
        // 财务报表临时数据
        FinancialYearData: {},
        taxData: {
            nowYear: {
                year: "",
                data: [],
            },
            lastYear: {
                year: "",
                data: [],
            },
            oldYear: {
                year: "",
                data: [],
            },
        },
        loseTaxData: [],
        wfwzTax: [],
        basicDataShow: false,
        loginDiv: true,
        sendSms: false,
        loginShow: false,
    },
};

export default manageDataOption;
