import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-69b5a821"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "identityBox"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  key: 0,
  class: "checkspan"
};
const _hoisted_4 = {
  key: 0,
  class: "maxshare"
};
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'Identity',
  props: ['index', 'item', 'data'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const data = computed(() => props.data);
    const word = {
      name: 'Identity'
    };

    //更改选中状态
    const changeFunc = (value, event) => {
      switch (event.target.name) {
        case '法人':
          item.value.listValue[0].select = value;
          value == false ? item.value.listValue[0].minValue = '' : null;
          break;
        case '股东':
          item.value.listValue[1].select = value;
          item.value.listValue[1].inputShow = true;
          const list = item.value.selected;
          if (list.includes('最大股东')) {
            let index = list.indexOf("最大股东");
            list.splice(index, 1);
          }
          value == false ? item.value.listValue[1].minValue = '' : null;
          break;
      }
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
          modelValue: _unref(item).selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(item).selected = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(item).listValue, (item, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: item.name,
              value: item.value,
              name: item.name,
              onChange: changeFunc,
              key: index
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1), item.select ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", null, [_createTextVNode("最低股份"), _createVNode(_component_el_input, {
                class: "input",
                modelValue: item.minValue,
                "onUpdate:modelValue": $event => item.minValue = $event,
                modelModifiers: {
                  number: true
                }
              }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createTextVNode("%")]), item.inputShow ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_checkbox, {
                label: "最大股东"
              })])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]),
              _: 2
            }, 1032, ["label", "value", "name"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["index", "data"]);
    };
  }
};