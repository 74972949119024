import { get, post } from '@/utils/service'

// 获取所有渠道信息
const getAllChannel = () => get('channel/getAllChannel')

// 获取渠道详情
const getChannelDetails = (data) => post('channel/getChannelDetails', data)

// 保存渠道信息
const saveChannelInfo = (data) => post('channel/saveChannelInfo', data)

// 获取单一渠道信息
const getChannelOneTeam = (data) => post('channel/getChannelOneTeam', data)

// 保存渠道团队信息
const saveChannelTeams = (data) => post('channel/saveChannelTeams', data)

// 删除团队
const delChannelTeam = (data) => post('channel/delChannelTeam', data)

// 保存渠道人员信息
const saveChannelPerson = (data) => post('channel/saveChannelPerson', data)

// 删除人员信息
const delChannelPerson = (data) => post('channel/delChannelPerson', data)

// 删除渠道
const delChannel = (data) => post('channel/delChannel', data)

// 资金方(增删改查)
const funders = (data) => post('channel/funders', data)


export default {
    getAllChannel,
    getChannelDetails,
    saveChannelInfo,
    saveChannelTeams,
    getChannelOneTeam,
    saveChannelPerson,
    delChannelTeam,
    delChannelPerson,
    delChannel,
    funders
}