import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-13837ac6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "btnZone"
};
const _hoisted_2 = {
  class: "Zone"
};
import { ref, computed, onMounted } from 'vue';
import city from '@/store/other/city';
import { ElMessage } from 'element-plus';
export default {
  __name: 'ChinaArea',
  props: ['item'],
  setup(__props) {
    const props = __props;
    const treeRef = ref(null);
    const defaultPermTreeSelect = ref([]);
    const item = computed(() => props.item);
    const defaultProps = {
      children: 'children',
      label: 'label'
    };

    // 所有的ID
    const allId = () => {
      const s = [];
      city.forEach(item => {
        s.push(item.id);
      });
      return s;
    };

    // 全选
    const selectAll = () => {
      defaultPermTreeSelect.value = allId();
    };

    // 重置
    const reset = () => {
      treeRef.value.setCheckedKeys([], false);
    };

    // 已选中的值
    const selected = computed(() => {
      const dataList = treeRef.value.getCheckedNodes();
      let s = [];
      dataList.forEach(item => {
        s.push(item.id);
      });
      return s;
    });

    // 确认
    const confirmValue = async () => {
      item.value.value = selected.value;
      ElMessage('已确认，保存请点击保存规则');
    };
    onMounted(() => {
      defaultPermTreeSelect.value = item.value.value;
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tree = _resolveComponent("el-tree");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
        onClick: confirmValue
      }, {
        default: _withCtx(() => [_createTextVNode("确认")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: selectAll
      }, {
        default: _withCtx(() => [_createTextVNode("全选")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: reset
      }, {
        default: _withCtx(() => [_createTextVNode("重置")]),
        _: 1
      })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tree, {
        ref_key: "treeRef",
        ref: treeRef,
        data: _unref(city),
        "show-checkbox": "",
        "node-key": "id",
        "highlight-current": "",
        props: defaultProps,
        "default-checked-keys": defaultPermTreeSelect.value
      }, null, 8, ["data", "default-checked-keys"])])], 64);
    };
  }
};