
const channelIndexOptions = {
    namespaced :true,
    actions:{
        // 更新渠道数据
        updateChannels(context,value){
            context.commit('UPDATECHANNELS',value)
        }
    },
    mutations:{
        UPDATECHANNELS(state,value){
            state.channels = value
        }
    },
    state:{
        channels:[
            {
                id:'',
                name:'',
                shortName:'',
                channelType:''
            }
        ]
    },

    getters:{

    }
}

export default channelIndexOptions