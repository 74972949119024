import { get, post } from "@/utils/service";

// 新建组织
const newOrg = (data) => post("org/newOrg", data);

// 获取我的组织
const getMyOrg = (data) => get("org/getMyOrg", data);

// 获取我加入的组织
const getJoinOrgs = (data) => get("org/getJoinOrgs", data);

// 删除组织
const delOrg = (data) => post("org/delOrg", data);

// 成员路由-增，删改，查
const members = (data) => post("org/members", data);

// 团队-增，删改，查
const teams = (data) => post("org/teams", data);

export default {
    newOrg,
    getMyOrg,
    getJoinOrgs,
    members,
    teams,
    delOrg,
};
