import { recursionRouter, setDefaultRoute } from '@/utils/recursionRouter'
import router, { DynamicRoutes } from '@/router'
import api from '@/api'
import dynamicRoutes from '@/router/dynamicRouter'

const indexMenusOption = {
    namespaced: true,
    actions: {
        // 选择菜单
        selectState(context, value) {
            context.commit('SELECTSTATE', value)
        },
        // 清空菜单项所有的选中
        clearMenuActive(context) {
            context.commit('CLEARMENUACTIVE')
        },
        // 请求权限
        async FETCH_PERMISSION(context, value) {
            // 向服务器拿到权限数据
            const res = await api().user.getPermission()
            const permissionList = res.data

            // 筛选
            let routes = recursionRouter(permissionList, dynamicRoutes)
            let mainContainer = DynamicRoutes.find(v => v.path === '/')
            let children = mainContainer.children
            children.push(...routes)

            // 生成菜单
            context.commit('SET_MENU', children)

            // 设置默认路由
            setDefaultRoute([mainContainer])

            // 初始化路由
            let initRoutes = router.options.routes

            // 添加到路由
            DynamicRoutes.forEach((item) => {
                router.addRoute(item)
            })
            // 添加到vuex
            context.commit('SET_PERMISSION', [...initRoutes, DynamicRoutes])
        }
    },
    mutations: {
        SELECTSTATE(state, value) {
            state.currentMenu = value;
            let menuList = state.menus;
            // 下方应该做一个递归,还未做
            menuList.forEach((item) => {
                if (item.meta.name == value) {
                    item.class = 'activeTab'
                } else {
                    const childList = item.children;
                    if (childList) {
                        childList.forEach((childItem) => {
                            if (childItem.meta.name == value) {
                                menuList.forEach((i) => {
                                    i.class = '';
                                    let iList = i.children
                                    if (iList) {
                                        iList.forEach((o) => {
                                            o.class = ''
                                        })
                                    }
                                })

                                childItem.class = 'activeTab'
                            }
                        })
                    }

                }
            })

        },
        CLEARMENUACTIVE(state) {
            let menuList = state.menus;
            menuList.forEach((i) => {
                i.class = '';
                let iList = i.children
                if (iList) {
                    iList.forEach((o) => {
                        o.class = ''
                    })
                }
            })
        },
        SET_PERMISSION(state, routes) {
            state.permissionList = routes
        },
        CLEAR_PERMISSION(state) {
            state.permissionList = null
        },
        SET_MENU(state, menu) {
            state.menus = menu
        },
        CLEAR_MENU(state) {
            state.menus = []
        }
    },
    state: {
        permissionList: null,
        menus: [], //导航菜单
        currentMenu: ''  //高亮
    },
    getters: {

    }
}


export default indexMenusOption