// api管理页面
import user from "@/api/models/user";
import cusApi from "@/api/models/cusApi";
import caseApi from "@/api/models/caseApi";
import tools from "@/api/models/tools";
import proApi from "./models/proApi";
import channelApi from "./models/channelApi";
import wordApi from "./models/wordApi";
import controlApi from "./models/controlApi";
import manageApi from "./models/manageApi";
import homeApi from "./models/homeApi";
import msgApi from "./models/msgApi";
import orgApi from "./models/orgApi";

export default function () {
    return {
        user,
        cusApi,
        caseApi,
        tools,
        proApi,
        channelApi,
        wordApi,
        controlApi,
        homeApi,
        manageApi,
        msgApi,
        orgApi,
    };
}
