// 用于记录非主要模块的tabs标签记录,以便返回时能回到刚才的浏览区域

const nowActiveTab = {
    namespaced: true,
    state: {
        // 产品浏览页面的tab标签记录
        browse: 'first',
        // 案件浏览页面的tab标签记录
        browseCase: 'first',
        // 权限管理的tab标签记录
        permission: 'first'
    }
}

export default nowActiveTab