
const customerDetailsOptions = {
    namespaced :true,
    actions:{
        addCusList(context,value){
            context.commit('ADDCUSLIST',value)
        },
        // 删除各类里的临时tab位置list,标签删除时需要一起删除
        removeActiveTabTemp(context,value){
            context.commit('REMOVEACTIVETABTEMP',value)
        }
    },
    mutations:{
        ADDCUSLIST(state,value){
            state.cusList[value.cusId] = value.content
        },
        REMOVEACTIVETABTEMP(state,value){
            delete state.cusList[value]
        }
    },
    state:{
        cusList:{},
        recordInput:{
            content:'',
            isAdd:true,
            row:''
        }
    },
    getters:{

    }
}

export default customerDetailsOptions