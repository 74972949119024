import { get, post } from '@/utils/service'

// 根据字符串提交信息
function submitRequest(requestPath, data) {
    return post(requestPath, data)
}

// 获取用户名下所有客户信息
function getAllCustomer(data) {
    return post('/cus/getAllcus', data)
}

// 获取用户名下所有的客户ID及名称
const getAllcusName = () => {
    return get('/cus/getAllcusName')
}

// 根据客户ID获取客户详情
function getCustomerDetails(cusId) {
    return post('/cus/getDetails', cusId)
}

// 根据客户ID获取所有案件
const getCusAllCases = (cusId) => post('/cus/getCusAllCases', cusId)

// 保存用户基本信息
const saveCusDetails = (data) => post('/cus/saveCusDetails', data)

// 修改记录
const alterRecord = (data) => post('/cus/alterRecord', data)

// 删除记录
const delRecord = (data) => post('/cus/delRecord', data)

// 获取客户名称
const searchCus = (data) => post('/cus/searchCus', data)

// 删除客户
const delCus = (data) => post('/cus/delCus', data)



export default {
    submitRequest,
    getAllCustomer,
    getCustomerDetails,
    getCusAllCases,
    saveCusDetails,
    alterRecord,
    delRecord,
    getAllcusName,
    searchCus,
    delCus
}