
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faUser,
  faRunning,
  faUniversalAccess,
  faUserSecret,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faCarSide,
  faHome,
  faAddressCard,
  faChartLine,
  faSackDollar,
  faMoneyCheckAlt,
  faMoneyBillAlt,
  faPowerOff,
  faFileAlt,
  faCopyright,
  faAddressBook,
  faInfinity,
  faPencil,
  faBlog,
  faTorah

} from '@fortawesome/free-solid-svg-icons';

import {
  faTwitter,

} from '@fortawesome/free-brands-svg-icons';

import {

} from '@fortawesome/free-regular-svg-icons'


library.add(
  faUser,
  faRunning,
  faUniversalAccess,
  faTwitter,
  faUserSecret,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faCarSide,
  faHome,
  faAddressCard,
  faChartLine,
  faSackDollar,
  faMoneyCheckAlt,
  faMoneyBillAlt,
  faPowerOff,
  faFileAlt,
  faCopyright,
  faAddressBook,
  faInfinity,
  faPencil,
  faBlog,
  faTorah
);

export default FontAwesomeIcon

