const wordOption = {
  namespaced: true,
  state: {
    get manageWords() {
      return JSON.parse(localStorage.getItem("manageWords"));
    },
    set manageWords(value) {
      localStorage.setItem("manageWords", JSON.stringify(value));
    },

    get words() {
      return JSON.parse(localStorage.getItem("words"));
    },
    set words(value) {
      localStorage.setItem("words", JSON.stringify(value));
    }
  },
};

export default wordOption;
