// 用于临时数据

const tempDataOption = {
    namespaced: true,
    state: {
        // 企业工商数据临时搜索的数据
        tempCompany: {
            data: [],
            nowCompanyName: '',
        }
        ,
        comanyName: '',
        searchHistory: []
    }
}

export default tempDataOption