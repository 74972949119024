import { get, post } from "@/utils/service";

// 获取消息列表
const getMsg = () => get("msg/getMsg");

// 标记已读
const markReaded = (data) => post("msg/markReaded", data);

// 获取详细消息内容
const getDetailsMsg = (data) => post("msg/getDetailsMsg", data);

// 删除消息
const deleteMsg = (data) => post("msg/deleteMsg", data);

export default {
    getMsg,
    markReaded,
    getDetailsMsg,
    deleteMsg,
};
