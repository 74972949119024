import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'SalesCompanyCount',
  props: ['index', 'item', 'data'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const data = computed(() => props.data);
    const word = {
      name: 'SalesCompanyCount'
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, {
        default: _withCtx(() => [_createTextVNode(" 近"), _createVNode(_component_el_input, {
          modelValue: _unref(item).value.one,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(item).value.one = $event),
          class: "input"
        }, null, 8, ["modelValue"]), _createTextVNode("月，开票的下游企业数量大于等于 "), _createVNode(_component_el_input, {
          modelValue: _unref(item).value.two,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(item).value.two = $event),
          class: "input"
        }, null, 8, ["modelValue"]), _createTextVNode("家 ")]),
        _: 1
      }, 8, ["index", "data"]);
    };
  }
};