import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import BaseRule from '../BaseRule.vue';
import { computed } from 'vue';
export default {
  __name: 'Area',
  props: ['index', 'item', 'data'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const data = computed(() => props.data);
    const word = {
      name: 'Area'
    };
    const areaValue = ['港、澳、台', '外国人'];
    return (_ctx, _cache) => {
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
          modelValue: _unref(item).value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(item).value = $event),
          label: _unref(item).label
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(areaValue, (item, index) => {
            return _createVNode(_component_el_checkbox, {
              key: index,
              label: item,
              value: item
            }, null, 8, ["label", "value"]);
          }), 64))]),
          _: 1
        }, 8, ["modelValue", "label"])]),
        _: 1
      }, 8, ["index", "data"]);
    };
  }
};