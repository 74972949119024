import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
import BaseRule from "../BaseRule.vue";
import { computed } from "vue";
export default {
  __name: 'NoCredit',
  props: ["index", "item"],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const word = {
      name: "NoCredit"
    };
    return (_ctx, _cache) => {
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index)
      }, {
        default: _withCtx(() => [_createTextVNode(" 征信白户可做 "), _createVNode(_component_el_checkbox, {
          label: "需加担保",
          class: "danbao",
          modelValue: _unref(item).value.one,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(item).value.one = $event)
        }, null, 8, ["modelValue"]), _createElementVNode("div", null, [_unref(item).value.one ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          modelValue: _unref(item).value.two,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(item).value.two = $event),
          placeholder: "担保要求",
          class: "danbao-input"
        }, null, 8, ["modelValue"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["index"]);
    };
  }
};