import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BaseRule from "../BaseRule.vue";
import { inject, computed } from "vue";
export default {
  __name: 'LegalLatestChange',
  props: ["index", "data"],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const data = computed(() => props.data);
    const word = {
      name: "LegalLatestChange"
    };
    // const proInfo = inject('proInfo')
    // 找到在列表中的本组件
    const valueData = computed(() => data.value.value[index.value]);
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index),
        data: _unref(data)
      }, {
        default: _withCtx(() => [_createTextVNode(" 法人代表变更满 "), _createVNode(_component_el_input, {
          modelValue: _unref(valueData).value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(valueData).value = $event),
          modelModifiers: {
            number: true
          },
          class: "input"
        }, null, 8, ["modelValue"]), _createTextVNode(" 月 ")]),
        _: 1
      }, 8, ["index", "data"]);
    };
  }
};