import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3befe26c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Zone customCom"
};
const _hoisted_2 = {
  class: "ZoneLeft formZone"
};
const _hoisted_3 = {
  key: 0,
  class: "btnEdit"
};
import { computed } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';
export default {
  __name: 'BaseRule',
  props: ['word', 'index', 'data'],
  setup(__props) {
    const props = __props;
    const data = computed(() => props.data);
    // 索引
    const index = computed(() => props.index);
    // 传过来的字段
    const word = computed(() => props.word);
    // 数据
    const valueData = computed(() => data.value.value[index.value]);
    const editComponent = computed(() => data.value.editComponent);

    // 注销组件
    const closeSelf = () => {
      data.value.value.splice(index.value, 1);
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        "label-width": "100px",
        "label-position": "left"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form_item, {
          label: _unref(valueData).label,
          class: "itemMargin"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(_toDisplayString(_unref(word).first), 1), _createVNode(_component_el_input, {
            modelValue: _unref(valueData).value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(valueData).value = $event),
            class: "input"
          }, null, 8, ["modelValue"]), _createTextVNode(_toDisplayString(_unref(word).second), 1)])]),
          _: 3
        }, 8, ["label"])])]),
        _: 3
      }), _unref(editComponent) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_button, {
        text: "",
        onClick: closeSelf,
        icon: _unref(CloseBold)
      }, null, 8, ["icon"])])) : _createCommentVNode("", true)]);
    };
  }
};