function timeToTimestamp(time) {
    let timestamp = Date.parse(new Date(time).toString());
    //timestamp = timestamp / 1000; //时间戳为13位需除1000，时间戳为13位的话不需除1000
    return timestamp;
    //2021-11-18 22:14:24的时间戳为：1637244864707
}

// 时间戳，根据输出的分钟往后延长
function newTimestamp(minutes) {
    let time = new Date()
    let min = time.getMinutes()
    time.setMinutes(min + minutes)
    let timestamp = Date.parse(time.toString());
    return timestamp
}


export {
    timeToTimestamp,
    newTimestamp,
}