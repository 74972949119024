const customerIndexOptions = {
    namespaced :true,
    actions:{
        // 获得数据
        getAllcustomers(context,value){
            context.commit('GETALLCUSTOMERS',value)
        }
    },
    mutations:{
        GETALLCUSTOMERS(state,value){
            state.customers = value
        }
    },
    state:{
        customers: []
    },
    getters:{

    }
}

export default customerIndexOptions