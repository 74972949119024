import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c13d60e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "censusDisplayZone"
};
const _hoisted_3 = {
  class: "censusZone"
};
const _hoisted_4 = {
  class: "tagZone"
};
const _hoisted_5 = {
  class: "tagItem"
};
import BaseRule from '../BaseRule.vue';
import { computed, ref } from 'vue';
import ChinaArea from '../generalRules/ChinaArea.vue';
import { Edit } from '@element-plus/icons-vue';
import { getLabelName } from '@/hooks/other/city';
export default {
  __name: 'CensusRegister',
  props: ['index', 'item'],
  setup(__props) {
    const props = __props;
    const index = computed(() => props.index);
    const item = computed(() => props.item);
    const companyAreaShow = ref(false);
    const word = {
      name: 'CensusRegister'
    };
    const selectNameList = computed(() => {
      const idList = JSON.parse(JSON.stringify(item.value.value));
      const nameList = getLabelName(idList);
      return nameList;
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(BaseRule, {
        word: word,
        index: _unref(index)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_el_popover, {
          placement: "right",
          width: 600,
          trigger: "click"
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            text: "",
            style: {
              "margin-right": "16px"
            }
          }, {
            default: _withCtx(() => [_createTextVNode("点击显示详情")]),
            _: 1
          })]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectNameList), item => {
            return _openBlock(), _createElementBlock("div", _hoisted_3, [_createTextVNode(_toDisplayString(item.label) + " ", 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, citem => {
              return _openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(citem.label), 1);
            }), 256))])]);
          }), 256))])]),
          _: 1
        })]), _createElementVNode("div", null, [_createVNode(_component_el_button, {
          text: "",
          onClick: _cache[0] || (_cache[0] = $event => companyAreaShow.value = true),
          icon: _unref(Edit)
        }, null, 8, ["icon"]), _createVNode(_component_el_dialog, {
          modelValue: companyAreaShow.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => companyAreaShow.value = $event),
          title: "户籍禁入"
        }, {
          default: _withCtx(() => [_createVNode(ChinaArea, {
            item: _unref(item)
          }, null, 8, ["item"])]),
          _: 1
        }, 8, ["modelValue"])])])]),
        _: 1
      }, 8, ["index"]);
    };
  }
};