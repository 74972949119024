import { get, post } from '@/utils/service'

// 案件统计
const caseStatistics = (data) => post('home/caseStatistics', data)

// 客户统计
const cusStatistics = (data) => post('home/cusStatistics', data)

// 客户统计
const proStatistics = (data) => post('home/proStatistics', data)

// 客户统计
const channelStatistics = (data) => post('home/channelStatistics', data)

export default {
    caseStatistics,
    cusStatistics,
    proStatistics,
    channelStatistics
}