// 全局注册自定义组件

// 人的条件-组件
import Area from "@/tools/RuleSystem/components/person/Area.vue";
import Age from "@/tools/RuleSystem/components/person/Age.vue";
import Identity from "@/tools/RuleSystem/components/person/Identity.vue";
import Query from "@/tools/RuleSystem/components/person/Query.vue";
import DebtTotal from "@/tools/RuleSystem/components/person/DebtTotal.vue";
import PhoneInNetTime from "@/tools/RuleSystem/components/person/PhoneInNetTime.vue";
import ContinuousOverdue from "@/tools/RuleSystem/components/person/ContinuousOverdue.vue";
import TotalOverdue from "@/tools/RuleSystem/components/person/TotalOverdue.vue";
import NowOverdue from "@/tools/RuleSystem/components/person/NowOverdue.vue";
import NoCredit from "@/tools/RuleSystem/components/person/NoCredit.vue";
import General from "@/tools/RuleSystem/components/person/General.vue";
import CensusRegister from "@/tools/RuleSystem/components/person/CensusRegister.vue";
import Card from "@/tools/RuleSystem/components/person/Card.vue";
import DebtCount from "@/tools/RuleSystem/components/person/DebtCount.vue";
import CardAverage from "@/tools/RuleSystem/components/person/CardAverage.vue";
import QueryMyself from "@/tools/RuleSystem/components/person/QueryMyself.vue";
import NetLoanCount from "@/tools/RuleSystem/components/person/NetLoanCount.vue";
import QueryNoLoanCount from "@/tools/RuleSystem/components/person/QueryNoLoanCount.vue";
import QueryNotBank from "@/tools/RuleSystem/components/person/QueryNotBank.vue";
import QueryOrganizationNum from "@/tools/RuleSystem/components/person/QueryOrganizationNum.vue";

// 企业条件-组件
import CompanyType from "@/tools/RuleSystem/components/company/CompanyType.vue";
import AgeLimit from "@/tools/RuleSystem/components/company/AgeLimit.vue";
import LegalLatestChange from "@/tools/RuleSystem/components/company/LegalLatestChange.vue";
import Industry from "@/tools/RuleSystem/components/company/Industry.vue";
import RegisterArea from "@/tools/RuleSystem/components/company/RegisterArea.vue";
import TaxLever from "@/tools/RuleSystem/components/company/TaxLever.vue";
import TaxTime from "@/tools/RuleSystem/components/company/TaxTime.vue";
import SalesLoanRate from "@/tools/RuleSystem/components/company/SalesLoanRate.vue";
import QoQ from "@/tools/RuleSystem/components/company/QoQ.vue";
import YoY from "@/tools/RuleSystem/components/company/YoY.vue";
import TaxSales from "@/tools/RuleSystem/components/company/TaxSales.vue";
import RegisteredCapital from "@/tools/RuleSystem/components/company/RegisteredCapital.vue";
import TaxRecord from "@/tools/RuleSystem/components/company/TaxRecord.vue";
import GeneralCompany from "@/tools/RuleSystem/components/company/GeneralCompany.vue";
import ManageSalses from "@/tools/RuleSystem/components/company/ManageSalses.vue";
import CompanyArea from "@/tools/RuleSystem/components/company/CompanyArea.vue";
import ManageCountLength from "@/tools/RuleSystem/components/company/ManageCountLength.vue";
import ManageTimeLength from "@/tools/RuleSystem/components/company/ManageTimeLength.vue";
import NoManageDays from "@/tools/RuleSystem/components/company/NoManageDays.vue";
import SalesCompanyCount from "@/tools/RuleSystem/components/company/SalesCompanyCount.vue";
import TotalManage from "@/tools/RuleSystem/components/company/TotalManage.vue";
import LatelyManage from "@/tools/RuleSystem/components/company/LatelyManage.vue";
import RedManage from "@/tools/RuleSystem/components/company/RedManage.vue";
import CancellationManage from "@/tools/RuleSystem/components/company/CancellationManage.vue";
import MaxMonthRate from "@/tools/RuleSystem/components/company/MaxMonthRate.vue";
import NoManageMonth from "@/tools/RuleSystem/components/company/NoManageMonth.vue";
import CancellationAndRedRate from "@/tools/RuleSystem/components/company/CancellationAndRedRate.vue";
import NowOwingTaxes from "@/tools/RuleSystem/components/company/NowOwingTaxes.vue";
import TaxYear from "@/tools/RuleSystem/components/company/TaxYear.vue";
import ZeroTax from "@/tools/RuleSystem/components/company/ZeroTax.vue";
import LateTax from "@/tools/RuleSystem/components/company/LateTax.vue";
import Tag from "@/tools/RuleSystem/components/company/Tag.vue";

const components = {
    install: function (app) {
        app.component("Area", Area)
            .component("Age", Age)
            .component("Identity", Identity)
            .component("CompanyType", CompanyType)
            .component("LegalLatestChange", LegalLatestChange)
            .component("Industry", Industry)
            .component("RegisterArea", RegisterArea)
            .component("TaxLever", TaxLever)
            .component("Query", Query)
            .component("DebtTotal", DebtTotal)
            .component("TaxTime", TaxTime)
            .component("PhoneInNetTime", PhoneInNetTime)
            .component("SalesLoanRate", SalesLoanRate)
            .component("QoQ", QoQ)
            .component("TaxSales", TaxSales)
            .component("RegisteredCapital", RegisteredCapital)
            .component("TaxRecord", TaxRecord)
            .component("ContinuousOverdue", ContinuousOverdue)
            .component("TotalOverdue", TotalOverdue)
            .component("NowOverdue", NowOverdue)
            .component("NoCredit", NoCredit)
            .component("General", General)
            .component("GeneralCompany", GeneralCompany)
            .component("ManageSalses", ManageSalses)
            .component("CensusRegister", CensusRegister)
            .component("CompanyArea", CompanyArea)
            .component("ManageTimeLength", ManageTimeLength)
            .component("NoManageDays", NoManageDays)
            .component("Card", Card)
            .component("SalesCompanyCount", SalesCompanyCount)
            .component("TotalManage", TotalManage)
            .component("DebtCount", DebtCount)
            .component("YoY", YoY)
            .component("CardAverage", CardAverage)
            .component("QueryMyself", QueryMyself)
            .component("NetLoanCount", NetLoanCount)
            .component("QueryNoLoanCount", QueryNoLoanCount)
            .component("LatelyManage", LatelyManage)
            .component("RedManage", RedManage)
            .component("CancellationManage", CancellationManage)
            .component("MaxMonthRate", MaxMonthRate)
            .component("NoManageMonth", NoManageMonth)
            .component("CancellationAndRedRate", CancellationAndRedRate)
            .component("NowOwingTaxes", NowOwingTaxes)
            .component("TaxYear", TaxYear)
            .component("ZeroTax", ZeroTax)
            .component("LateTax", LateTax)
            .component("ManageCountLength", ManageCountLength)
            .component("Tag", Tag)
            .component("AgeLimit", AgeLimit)
            .component("QueryNotBank", QueryNotBank)
            .component("QueryOrganizationNum", QueryOrganizationNum);
    },
};
export default components;
