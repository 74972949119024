const caseDetailsOptions = {
    namespaced: true,
    actions: {
        // addCaseList
        addCaseList(context, value) {
            context.commit("ADDCASELIST", value);
        },
        // 更新简版征信自动识别信息
        updateCreditAuto(context, value) {
            context.commit("UPDATECREDITAUTO", value);
        },

        // 更新当前操作
        updateSelectedOpration(context, data) {
            context.commit("UPDATESELECTEDOPRATION", data);
        },
        // 更新所有的客户ID及名称
        updateAllCusName(context, value) {
            context.commit("UPDATEALLCUSNAME", value);
        },
        // 更新所有的产品经理ID及名称
        updateAllProducterName(context, value) {
            context.commit("UPDATEALLPRODUCTERNAME", value);
        },
        // 删除各类里的临时tab位置list,标签删除时需要一起删除
        removeActiveTabTemp(context, value) {
            context.commit("REMOVEACTIVETABTEMP", value);
        },
    },
    mutations: {
        ADDCASELIST(state, value) {
            state.caseList[value.caseId] = value.content;
        },
        UPDATECREDITAUTO(state, value) {
            state.nowCase.applicant.creditAuto = value;
        },
        UPDATESELECTEDOPRATION(state, data) {
            state.nowOperation = data.item;
            // 将值缓存
            state.operationpTemp.label = data.item.proName;
            state.operationpTemp.value = data.item.proNameValue;

            state.nowOperation.proName = data.item.proNameValue.split("/");

            // 不是新增
            state.nowOperationConfig.isAdd = false;
            state.nowOperationConfig.index = data.index;
        },
        UPDATEALLCUSNAME(state, value) {
            state.allCusName = value;
        },
        UPDATEALLPRODUCTERNAME(state, value) {
            state.allProducterName = value;
        },
        REMOVEACTIVETABTEMP(state, value) {
            delete state.caseList[value];
        },
    },
    state: {
        words: {
            baseInfo: {
                // labelState :['业务处理','处理中','已完成','等待客户'],
                // labelType : ['企业','房产','公积金','工作','保单','车辆','有价证券','其它'],
                producter: [
                    {
                        producterId: "1",
                        producterName: "晓长源",
                    },
                    {
                        producterId: "2",
                        producterName: "谭大仙",
                    },
                ],
                // comapnyBaseInfo:[
                //     '法人姓名','营业状态','税号','企业类型','成立日期','营业期限','行业ID','行业','注册资本','实缴资本',
                //     '企业规模','参保人数','纳税类型','纳税银行','报税电话','曾用名','地区','登记机关','注册地址','经营范围','最后变更日期']
            },
            relation: ["本人", "配偶", "股东", "亲戚", "朋友"],
        },
        caseList: {},
        nowOperation: {
            ids: "",
            proName: "",
            proNameValue: "",
            startTime: "",
            endTime: "",
            state: "",
            resultMonny: "",
            refuseReason: "",
            productManager: "",
        },
        operationpTemp: {
            value: [],
            label: "",
        },
        nowOperationConfig: {
            isAdd: true,
            index: "",
        },
        allCusName: [{ cusId: "", cusName: "" }],
        allProducterName: [],
        plan: [],
    },
    getters: {},
};

export default caseDetailsOptions;
